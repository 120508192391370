import React, { Component } from 'react';
import {Container, Row, Col, Card, Button, Form} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Settings from './Settings';
import axios from 'axios';
import * as md5 from 'md5';

const profileStyle = {
    padding:'80px 80px 0 80px',
    textAlign:'center'
}
class Profile extends Component{
    constructor(props)
    {
        super(props)
        this.state={
            change_password_shown:false,
            new_password:null
        };
        this.settings = new Settings();
    }

    input_new_password = () => {
        let password = document.getElementById('change_password').value
        let re_password = document.getElementById('change_re_password').value

        if (password === re_password) 
            this.setState({new_password: password});
    }

    change_password = (evt) => {
        evt.preventDefault();
        let old_password = document.getElementById('change_password_old').value
        if (!old_password.length){
            alert('We need the old password');
            return;
        }

        if (this.state.new_password){
            axios.post(`${this.settings.API_address}/change_password`,
            {
                user_uuid:this.props.user.UUID,
                old_password:md5(old_password),
                password:md5(this.state.new_password)
            }).then(res => {
                if(res.data.success){
                    this.props.history.push('/login');
                }else{
                    alert(res.data.error_message)
                }
            });
        }else{
            alert('Passwords are not match');
        }
    }

    toggle_change_password = (evt) => {
        evt.preventDefault();
        this.setState({change_password_shown: !this.state.change_password_shown})
    }

    render(){
        return (
            this.props.loggedIn ?
            <Container style={profileStyle} fluid={true}>
                <Row lg={{cols:5}} md={{cols:1}} sm={{cols:1}}>
                    <Col >
                        <Card style={{ textAlign:'center'}}>
                        <Card.Body>
                            <Card.Title>ПОТРЕБИТЕЛ</Card.Title>
                            <Button style={{backgroundColor:'#2080b9'}}>{this.props.user.name}</Button>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col >
                        <Card style={{ textAlign:'center'}}>
                        <Card.Body>
                            <Card.Title>ИМЕЙЛ АДРЕС</Card.Title>
                            <Button style={{backgroundColor:'#2080b9'}}>{this.props.user.email}</Button>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col >
                        <Card style={{ textAlign:'center'}}>
                        <Card.Body>
                            <Card.Title>ПАРОЛА</Card.Title>
                            <Row>
                                <Col>
                                    <Button style={{backgroundColor:'#2080b9'}}>********</Button>
                                </Col>
                                <Col>
                                    <Button style={{backgroundColor:'#2080b9'}} onClick={this.toggle_change_password}>Промени паролата</Button>
                                </Col>
                            </Row>
                            
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {this.state.change_password_shown ? <Row>
                    <Col>
                    <Form onSubmit={this.change_password}>
                        <Form.Group>
                        <Form.Label>Стара парола</Form.Label>
                        <Form.Control type="password" placeholder="Въведете старата парола" id="change_password_old"/>
                        </Form.Group>
                    
                        <Form.Group>
                            <Form.Label>Нова Парола</Form.Label>
                            <Form.Control required type="password" placeholder="Новата парола трябва да бъде с дължина от 5 до 30 символа" id="change_password"/>
                            <Form.Label>Новата парола отново</Form.Label>
                            <Form.Control required type="password" placeholder="Новата парола трябва да бъде с дължина от 5 до 30 символа" id="change_re_password" onChange={this.input_new_password}/>
                        </Form.Group>
                        <Button style={{backgroundColor:'#2080b9'}} type="submit">
                            Промени паролата
                        </Button>
                    </Form>
                    </Col>
                </Row> : null}
            </Container>
            :
            <Redirect to={"/login"}/>
        )
    }
}

export default Profile;
