import React, { Component } from 'react';
import {Card, Button} from 'react-bootstrap';
import Settings from './Settings';

class PricingElement extends Component{
    constructor(props){
        super(props);
        this.settings = new Settings();
    }

    selectPlan = ()=>{
        this.props.handleSelectPlan(this.props.item);
    }

    render(){
        return (
            <Card style={{ textAlign:'center' }}>
                <Card.Img variant="top" style={this.settings.theme} src={this.props.image} />
                <Card.Body>
                    <Card.Title>{this.props.size}</Card.Title>
                    <br/>
                    <Card.Subtitle>{this.props.price}</Card.Subtitle>
                    <br/>
                    <Card.Subtitle>{`Ако изберете плащане за година ще спестите: ${this.props.safe}`}</Card.Subtitle>
                    <br/>
                    <Card.Text>{this.props.content}</Card.Text>
                    <br/>
                    <Button onClick={this.selectPlan} style={this.settings.theme}>ИЗБЕРИ СЕГА</Button>
                </Card.Body>
            </Card>
        )
    }
}

export default PricingElement;
