import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Settings from './Settings';

class Navigation extends Component {
    constructor(props){
        super(props);
        this.settings = new Settings();
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" variant="dark" style={this.settings.theme} fixed="top">
                <Navbar.Brand href="/">CCLOUD {this.props.userName}</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {!this.props.loggedIn ? <Nav.Link id="navbar_pricing" href="/#pricing">Цени</Nav.Link> : null}
                        {!this.props.loggedIn ? <Nav.Link id="navbar_demo" href="/#demo">Демо</Nav.Link> : null}
                        {!this.props.loggedIn ? <Nav.Link id="navbar_why_ccloud" href="/#why_ccloud">Защо CCLOUD</Nav.Link> : null}
                        {!this.props.loggedIn ? <Nav.Link id="navbar_support" href="/#support">Помощ</Nav.Link> : <Nav.Link href="mailto:support@ccloud.systems">Support</Nav.Link>}
                    </Nav>
                    <Nav>
                        {this.props.loggedIn ? <Nav.Link id="navbar_profile" href="/profile">Профил</Nav.Link> : <div></div>}
                        {!this.props.loggedIn ? <Nav.Link id="navbar_register" href="/register">Регистрация</Nav.Link> : <div></div>}
                        <Nav.Link id="navbar_login_logout" href={this.props.loggedIn ? "/logout" : "/login"}>{this.props.loggedIn ? "Изход" : "Вход"}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Navigation;
