import React, { Component } from 'react';
import {Form, Button, Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import Settings from './Settings';
import * as md5 from 'md5';

const settings = new Settings();

const loginStyle = {
    padding:'80px 0 0 0'
}

class Register extends Component{
    constructor(props)
    {
        super(props)
        this.state={
            email:'',
            user_name:'',
            password:'',
            cloud_name:'',
            cloud_size: this.props.selected_plan || '10G',
            payment_period: 'month',
            payment_card_number: '',
            payment_card_cvv: ''
        }
    }
    
    input_cloud_name = (evt) => {
        this.setState({cloud_name: evt.target.value})
    }

    select_cloud_size = (evt) => {
        this.setState({cloud_size: evt.target.value})
    }

    select_payment_period = (evt) => {
        this.setState({payment_period: evt.target.value})
    }

    input_email = () => {
        this.setState({email: document.getElementById('user_email').value})
    }

    input_username = () => {
        this.setState({user_name: document.getElementById('user_name').value})
    }

    input_password = () => {
        let password = document.getElementById('register_password').value
        let re_password = document.getElementById('register_re_password').value

        if (password === re_password) 
            this.setState({password: password})
    }

    register = (evt) =>{
        const form = evt.currentTarget;
        evt.preventDefault();
        if (!form.checkValidity()) {
            evt.stopPropagation();
            return;
        }

        window.grecaptcha.ready(() => {
            try{
                window.grecaptcha.execute('6LdDZVMaAAAAADxJjQMiccckglE8zD1uFPiYLOTs', {action: 'submit'}).then((token) => {
                
                axios.post(`${settings.API_address}/register`,
                {
                    username:this.state.user_name,
                    email:this.state.email.toLowerCase(),
                    password:md5(this.state.password),
                    cloud_name:this.state.cloud_name.toLowerCase(),
                    cloud_size: this.state.cloud_size
                }).then(res => {
                    if(res.data.success){
                        this.props.history.push('/login');
                    }else{
                        alert(res.data.error_message)
                    }
                    });
                })
            } catch(e){
                alert(e)
            }
        });
        
    }

    render(){
        return (
            <Container style={loginStyle}>
                <Row>
                    <Col>
                        <Form onSubmit={this.register} autoComplete='false' >
                            {/* Email */}
                            <Form.Group>
                                <Form.Label>Имейл адрес</Form.Label>
                                <Form.Control required type="email" placeholder="Въведете вашия имейл адрес" id='user_email' onChange={this.input_email}/>
                                <Form.Text className="text-muted">
                                    Ние никога няма да споделим вашият имейл адрес с някой друг
                                </Form.Text>
                            </Form.Group>
                            {/* Username */}
                            <Form.Group>
                                <Form.Label>Потребителско Име</Form.Label>
                                <Form.Control required type="text" placeholder="Потребителското име трябва да бъде с дължина от 5 до 30 символа, специални символи и цифри не са разрешени" id='user_name' onChange={this.input_username}/>
                            </Form.Group>
                            {/* Password */}
                            <Form.Group>
                                <Form.Label>Парола</Form.Label>
                                <Form.Control required type="password" placeholder="Парола трябва да бъде с дължина от 5 до 30 символа" id="register_password"/>
                                <Form.Label>Паролата отново</Form.Label>
                                <Form.Control required type="password" placeholder="Парола трябва да бъде с дължина от 5 до 30 символа" id="register_re_password" onChange={this.input_password}/>
                            </Form.Group>
                            {/* Cloud name */}
                            <Form.Group>
                                <Form.Label>Име на вашия CCLOUD</Form.Label>
                                <Form.Control required type="text" placeholder="Името на вашия CCLOUD може да съдържа само букви, специални символи и цифри не са разрешени" onChange={this.input_cloud_name}/>
                            </Form.Group>
                            {/* Cloud size */}
                            <Form.Group>
                                <Form.Label>Размер</Form.Label>
                                <Form.Control required as="select" defaultValue={this.props.selected_plan} onChange={this.select_cloud_size}>
                                    <option value={'10G'}>10 GB</option>
                                    <option value={'20G'}>20 GB</option>
                                    <option value={'50G'}>50 GB</option>
                                </Form.Control>
                            </Form.Group>
                            <Button style={settings.theme} type="submit">
                                Регистрация
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Register;