import React, { Component } from 'react';
import { Container, Row, Col, Button, Card} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Settings from './Settings';

const monitoringStyle = {
    padding:'80px 80px 0 80px',
    textAlign:'center'
}

const CLOUD_STATUS = {
    WAIT_FOR_EMAIL_VERIFICATION:    "WAIT_FOR_EMAIL_VERIFICATION",
    WAIT_FOR_EMAIL_VERIFICATION_1:  "WAIT_FOR_EMAIL_VERIFICATION_1",
    WAIT_FOR_EMAIL_VERIFICATION_3:  "WAIT_FOR_EMAIL_VERIFICATION_3",
    WAIT_FOR_EMAIL_VERIFICATION_5:  "WAIT_FOR_EMAIL_VERIFICATION_5",
    WAIT_FOR_EMAIL_VERIFICATION_10: "WAIT_FOR_EMAIL_VERIFICATION_10",
    FREE_TRIAL:                     "FREE_TRIAL",                   // In free trial period (10 days)
    FREE_TRIAL_ENDS_IN_3_DAYS:      "FREE_TRIAL_ENDS_IN_3_DAYS",
    ACTIVE:                         "ACTIVE",                       // Claud is paied and active
    ACTIVE_ENDS_IN_10_DAYS:         "ACTIVE_ENDS_IN_10_DAYS",
    ACTIVE_ENDS_IN_5_DAYS:          "ACTIVE_ENDS_IN_5_DAYS",
    ACTIVE_ENDS_IN_1_DAYS:          "ACTIVE_ENDS_IN_1_DAYS",
    STOPPED:                        "STOPPED",                      // Docker container is stopped for access but it keep all files 7 days and wait for payment
    DELETED:                        "DELETED"                       // All user files are deleted and docker container is stopped
};

const CLOUD_STATUS_INFO = {
    WAIT_FOR_EMAIL_VERIFICATION:    "Email not verified",
    WAIT_FOR_EMAIL_VERIFICATION_1:  "Email not verified",
    WAIT_FOR_EMAIL_VERIFICATION_3:  "Email not verified",
    WAIT_FOR_EMAIL_VERIFICATION_5:  "Email not verified",
    WAIT_FOR_EMAIL_VERIFICATION_10: "Email not verified",
    FREE_TRIAL:                     "Free trial",
    FREE_TRIAL_ENDS_IN_3_DAYS:      "Ends in 3 days",
    ACTIVE:                         "Active",
    ACTIVE_ENDS_IN_10_DAYS:         "Ends in 10 days",
    ACTIVE_ENDS_IN_5_DAYS:          "Ends in 5 days",
    ACTIVE_ENDS_IN_1_DAYS:          "Ends in 1 days",
    STOPPED:                        "STOPPED",
    DELETED:                        "DELETED" 
}
class Monitoring extends Component{
    constructor(props)
    {
        super(props);
        this.state ={
            
        }
        this.settings = new Settings();
    }

    pay_now_redirect = (evt) => {
        window.location.href = "/pay"
    }

    render(){
        if(!this.props.email_valid){
            return <div style={monitoringStyle}>Моля валидирайте вашия имейл адрес за да активирате профилът си в CCLOUD</div>
        }
        return (
            this.props.loggedIn ?
            <Container style={monitoringStyle} fluid={true}>
                <Row lg={{cols:5}} md={{cols:1}} sm={{cols:1}}>
                    <Col >
                        <Card style={{ textAlign:'center'}}>
                        <Card.Body>
                            <Card.Title>СТАТУС</Card.Title>
                            <Button style={this.settings.theme}>{CLOUD_STATUS_INFO[this.props.cloud.status]}</Button>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ textAlign:'center'}}>
                        <Card.Body>
                            <Card.Title>МОЯТ CCLOUD</Card.Title>
                            <Button style={this.settings.theme} onClick={[CLOUD_STATUS.ACTIVE, CLOUD_STATUS.DELETED].indexOf(this.props.cloud.status) === -1 ? ()=>{window.open(`https://ccloud.systems/${this.props.cloud.name}`)} : null}>{this.props.cloud.name.toUpperCase()}</Button>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col >
                        <Card style={{ textAlign:'center'}}>
                        <Card.Body>
                            <Card.Title>РАЗМЕР</Card.Title>
                            <Button style={this.settings.theme}>{this.props.cloud.sizeMB}MB</Button>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col >
                        <Card style={{ textAlign:'center'}}>
                        <Card.Body>
                            <Card.Title>АКТИВЕН ДО</Card.Title>
                            <Button style={this.settings.theme}>{this.props.cloud.activeTo}</Button>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col >
                        <Card style={{ textAlign:'center'}}>
                        <Card.Body>
                            <Card.Title>ПЛАЩАНИЯ</Card.Title>
                            <Button style={this.settings.theme}>{this.props.cloud.last_payment_date || 'N/A'}</Button>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {[CLOUD_STATUS.ACTIVE, CLOUD_STATUS.DELETED].indexOf(this.props.cloud.status) === -1 ? <Button style = {{width:'100%', margin:'3em 0em 3em 0em', backgroundColor:'#2080b9'}} onClick={this.pay_now_redirect}>ПЛАТИ СЕГА</Button> : null}
            </Container>
            :
            <Redirect to={"/login"}/>
        )
    }
}

export default Monitoring;
