import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import Home from './components/Home';
import Monitoring from './components/Monitoring';
import Profile from './components/Profile';
import Navigation from './components/Navbar';
import Login from './components/Login';
import Register from './components/Register';
import Payment from './components/Payment';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import axios from 'axios';
import Settings from './components/Settings';
const settings = new Settings();
const publicIp = require('public-ip');

function HomePage(properties) {
  if (properties.properties.app.loggedIn)
    return <Monitoring
      loggedIn={properties.properties.app.loggedIn}
      user={properties.properties.user}
      cloud={properties.properties.cloud}
      email_valid={properties.properties.user.email_valid}
    />;
  else
    return <Home {...properties} />;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.session = JSON.parse(sessionStorage.getItem('auth_data'));
    this.state = {
      selected_plan: null,
      app: {
        loggedIn: false
      },
      user: {
        UUID: '',
        name: '',
        email: '',
        email_valid: false
      },
      cloud: {
        uuid: '',
        name: '',
        status: '',
        sizeMB: '',
        last_payment_date: ''
      }
    }
    this.read_sesson();
    this.handleSuccessfulAuth = this.handleSuccessfulAuth.bind(this);
    this.handleSuccessfulPayment = this.handleSuccessfulPayment.bind(this);
    this.handleSelectPlan = this.handleSelectPlan.bind(this);

    document.addEventListener("Click", async (evt) => {
      let IPv4 = await publicIp.v4();
      let activityObject = {
        target: {
          elementType:evt.toElement.tagName,
          address:evt.toElement.href || evt.toElement.currentSrc || "N/A"
        },
        source: {
          address: window.location.href
        },
        timestamp: new Date().getTime(),
        stayAtPageSeconds: parseInt(evt.timeStamp/1000),
        IPv4:IPv4,
        cookie: document.cookie
      };

      axios.post(`${settings.API_address}/log_actions`,
        activityObject
      );
    });
  }

  hide_email = (email) => {
    let prefix = email.split('@')[0];
    let suffix = email.split('@')[1];
    return `${prefix[0]}${"*".repeat(prefix.length - 2)}${prefix[prefix.length - 1]}@${suffix}`;
  }

  read_sesson = () => {
    if (this.session && this.session.success) {
      // eslint-disable-next-line
      this.state = {
        app: {
          loggedIn: this.session.success
        },
        user: {
          UUID: this.session.monitoring_data.user_uuid,
          name: this.session.monitoring_data.user_name,
          email: this.hide_email(this.session.monitoring_data.user_email),
          email_valid: this.session.monitoring_data.user_email_valid
        },
        cloud: {
          name: this.session.monitoring_data.cloud_name,
          status: this.session.monitoring_data.cloud_status,
          sizeMB: this.session.monitoring_data.cloud_size,
          activeTo: this.session.monitoring_data.cloud_active_to ? this.session.monitoring_data.cloud_active_to.slice(0, 10) : '',
          last_payment_date: this.session.monitoring_data.cloud_last_payment_date ? this.session.monitoring_data.cloud_last_payment_date.slice(0, 10) : '',
          uuid: this.session.monitoring_data.cloud_uuid,
        }
      };
    }
  }

  handleSuccessfulAuth = (auth_data) => {
    sessionStorage.setItem('auth_data', JSON.stringify(auth_data));
    auth_data.success ?
      this.setState({
        app: {
          loggedIn: auth_data.success
        },
        user: {
          UUID: auth_data.monitoring_data.user_uuid,
          name: auth_data.monitoring_data.user_name,
          email: this.hide_email(auth_data.monitoring_data.user_email),
          email_valid: auth_data.monitoring_data.user_email_valid
        },
        cloud: {
          name: auth_data.monitoring_data.cloud_name,
          status: auth_data.monitoring_data.cloud_status,
          sizeMB: auth_data.monitoring_data.cloud_size,
          activeTo: auth_data.monitoring_data.cloud_active_to ? auth_data.monitoring_data.cloud_active_to.slice(0, 10) : '',
          last_payment_date: auth_data.monitoring_data.cloud_last_payment_date ? auth_data.monitoring_data.cloud_last_payment_date.slice(0, 10) : '',
          uuid: auth_data.monitoring_data.cloud_uuid
        }
      }) :
      this.setState({ app: { loggedIn: auth_data.success } })
  }

  handleSuccessfulPayment = (response_data) => {
    if (response_data.success) {
      let session = JSON.parse(sessionStorage.getItem('auth_data'));
      session.monitoring_data.cloud_status = response_data.cloud_status;
      sessionStorage.setItem('auth_data', JSON.stringify(session));
      let cloud_data = this.state.cloud;
      cloud_data.status = response_data.cloud_status;
      cloud_data.last_payment_date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
      this.setState({ cloud: cloud_data });
    }
  }

  logout = () => {
    sessionStorage.clear();
    window.location.href = "/"
  }

  handleSelectPlan = (plan) => {
    this.setState({ selected_plan: plan });
  }

  addStyleToHead = () => {
    let style = document.createElement('style');
    style.innerHTML = `
    html {
      scroll-behavior:smooth;
    }`;
    document.head.appendChild(style);
  }
  render() {
    this.addStyleToHead();

    

    return (
      <React.Fragment>
        <Navigation loggedIn={this.state.app.loggedIn} userName={this.state.user.name} />

        <Router>
          <Route exact path="/" render={props => (
            <HomePage {...props} properties={this.state} handleSelectPlan={this.handleSelectPlan} />
          )} />

          <Route path="/profile" render={props => (
            <Profile {...props} loggedIn={this.state.app.loggedIn} user={this.state.user} />
          )} />

          <Route path="/login" render={props => (
            <Login {...props} handleSuccessfulAuth={this.handleSuccessfulAuth} />
          )} />

          <Route path="/logout" render={props => (
            this.logout()
          )} />

          <Route path="/register" render={props => (
            <Register {...props} handleSuccessfulAuth={this.handleSuccessfulAuth} selected_plan={this.state.selected_plan} />
          )} />

          <Route path="/pay" render={props => (
            <Payment
              {...props}
              loggedIn={this.state.app.loggedIn}
              cloud={this.state.cloud}
              user={this.state.user}
              handleSuccessfulPayment={this.handleSuccessfulPayment}
            />
          )} />
        </Router>
      </React.Fragment>
    )
  }
}

export default App;
