import React, { Component } from 'react';
import {Form, Button, Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import Settings from './Settings';
import * as md5 from 'md5';

const settings = new Settings();

const loginStyle = {
    padding:'80px 0 0 0'
}

class Login extends Component{

    constructor(props)
    {
        super(props);
        this.state ={
            username: '',
            password: '',
            user_uuid: '',
            is_logged_in: false
        };
        this.forgot_password_shown = false;
    }

    input_username = (evt) => {
        this.setState({username: evt.target.value})
    }

    input_password = (evt) => {
        let password = evt.target.value
        this.setState({password: password})
    }

    login = (evt) =>{
        evt.preventDefault();
        axios.post(`${settings.API_address}/login`,
        {
            username:this.state.username,
            password:md5(this.state.password)
        }).then(res => {
            if(res.data.success)
            {
                this.props.handleSuccessfulAuth(res.data);
                this.props.history.push('/');
            }else{
                alert(res.data.error_message);
            }
        });
    }

    toggle_forgot_password = (evt) =>{
        evt.preventDefault();
        this.setState({forgot_password_shown:!this.state.forgot_password_shown});
    }

    forgot_password = (evt)=>{
        evt.preventDefault();

        axios.post(`${settings.API_address}/forgot_password`,
        {
            email:evt.target[0].value
        }).then(res => {
            if(res.data.success)
            {
                alert('Check your email inbox for further steps.')
            }else{
                alert(res.data.error_message);
            }
        });
    }
    render(){
        return (
            <Container style={loginStyle}>
                <Row>
                    <Col>
                        <Form onSubmit={this.login}>
                            <Form.Group>
                            <Form.Label>Потребителско Име</Form.Label>
                            <Form.Control type="text" placeholder="Въведете вашето потребителско име" onChange={this.input_username}/>
                            </Form.Group>
                        
                            <Form.Group>
                            <Form.Label>Парола</Form.Label>
                            <Form.Control type="password" placeholder="Въведете вашата парола" onChange={this.input_password}/>
                            </Form.Group>
                            <Button style={{backgroundColor:`${settings.theme.backgroundColor}`, borderColor: `${settings.theme.borderColor}`, width:'100%'}} type="submit">Влез</Button>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button style={{backgroundColor:`${settings.theme.backgroundColor}`, borderColor: `${settings.theme.borderColor}`, width:'100%', marginTop:'2em'}} onClick={this.toggle_forgot_password}>Забравена парола</Button>
                    </Col>
                </Row>
                {this.state.forgot_password_shown?
                <Row style={{marginTop:'2em'}}>
                    <Col>
                    <Form onSubmit={this.forgot_password}>
                            <Form.Group>
                            <Form.Label>Вашият имейл адрес</Form.Label>
                            <Form.Control type="email" placeholder="Въведете вашия имейл адрес"/>
                            </Form.Group>
                            <Button style={{backgroundColor:`${settings.theme.backgroundColor}`, borderColor: `${settings.theme.borderColor}`, width:'100%'}} type="submit">Възстановяване</Button>
                        </Form>
                    </Col>
                </Row>:null}
            </Container>
        )
    }
}

export default Login;