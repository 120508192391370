import React, { Component } from 'react';
import { Form, Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Settings from './Settings';
// const dropin = require('braintree-web-drop-in');

const settings = new Settings();

const paymentStyle = {
    padding: '80px 80px 0 80px',
    textAlign: 'center'
}

class CloudRegister extends Component {


    constructor(props) {
        super(props);

        this.state = {
            payment_period: "month",
            total_price: Settings.price_list["month"][this.props.cloud.sizeMB],
            dropin_instance: null,
            payment_method_valid: false,
            client_nonce: null
        }

        window.paypal.Buttons({
            style: {
                layout:  'vertical',
                color:   'blue',
                shape:   'rect',
                label:   'pay'
              },
            createOrder: (data, actions) => {
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            currency_code: 'EUR',
                            value: parseInt(this.state.total_price)
                        }
                    }],
                    application_context: {
                        shipping_preference: 'NO_SHIPPING'
                    }
                });
            },
            onApprove: (data, actions) => {
                return actions.order.capture().then((details) => {
                    axios.post(`${settings.API_address}/checkout/${this.props.user.UUID}`,
                        {
                            amount: this.state.total_price,
                            period: this.state.payment_period,
                            cloud_uuid: this.props.cloud.uuid,
                            payment_details: details
                        }).then(res => {
                            if (res.data.success) {
                                this.setState({ wait_payment_status: false });
                                this.props.handleSuccessfulPayment(res.data);
                                this.props.history.push('/');
                            }
                        });
                });
            }
        }).render('#dropin-container');
    }

    checkout_button_click = (evt) => {
        this.state.dropin_instance.requestPaymentMethod((err, payload) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({ payment_method_valid: true, client_nonce: payload.nonce });
            }
        });
    }

    input_payment_period = (evt) => {
        this.setState({ total_price: Settings.price_list[evt.target.value][`${this.props.cloud.sizeMB}`], payment_period: evt.target.value })
    }

    render() {
        return (
            this.props.loggedIn && this.props.cloud.status !== "ACTIVE" ?
                <Container style={paymentStyle}>
                    <Form.Group>
                        <Form.Label>Period</Form.Label>
                        <Form.Control as="select" defaultValue='month' onChange={this.input_payment_period}>
                            <option value='month'>Месец</option>
                            <option value='year'>Година</option>
                        </Form.Control>
                    </Form.Group>
                    <h3>{`Thank you for your payment: ${this.state.total_price}€`}</h3>

                    <Container style={{ paddingTop: '80px' }} id="dropin-container">
                    </Container>
                </Container>
                : <Redirect to={"/"} />
        )
    }
}

export default CloudRegister;