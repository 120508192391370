const APP_MODES={
    DEBUG: "DEBUG",
    PRODUCTION: "PROD"
};

const THEMES = {
    DARK: {
        backgroundColor: '#343a40',
        borderColor: '#343a40'
    },
    SKY_BLUE: {
        backgroundColor: '#2080b9',
        borderColor: '#2080b9'
    }
};

class Settings{
    static price_list = {
        month:{
            '10240': "2",
            '‭20480‬': "3",
            '‭51200‬' :"5"
        },
        year:{
            '10240': "22",
            '‭20480‬': "33",
            '‭51200‬' :"55"
        }
    }

    static paypal_mapping = {
            '10240': "7YKAZ4WDBWPKQ",
            '‭20480‬': "USK69ZDEA33MW",
            '‭51200‬' :"6FQQYS3JVZ2ZW"
    }

    constructor(){
        this.app_mode = APP_MODES.PRODUCTION;
        this.theme = THEMES.SKY_BLUE;
        this.API_address = this.app_mode===APP_MODES.DEBUG ? 'http://localhost:1369': 'https://ccloud.systems/api'
    }
};

export default Settings;