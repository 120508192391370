import React, { Component } from 'react';
import {Carousel, Container, Row, Col, Card, Button} from 'react-bootstrap';
import PricingElement from './PricingElement';
import Settings from './Settings';

class Home extends Component{
    constructor(props){
        super(props);
        this.handleSelectPlan = this.handleSelectPlan.bind(this);
        this.settings = new Settings();
    }

    handleSelectPlan = (plan) => {
        this.props.handleSelectPlan(plan);
        this.props.history.push("/register");
    }

    render(){
        return(
            <React.Fragment>
                <Carousel>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 text-dark"
                        src={process.env.PUBLIC_URL + "ccloud_all_all_files.jpg"}
                        alt="Всички ваши файлове на едно място"
                        />
                        <Carousel.Caption>
                        <h3>Всички ваши файлове на едно място</h3>
                        <p>Всички ваши файлове на CCLOUD</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL + "ccloud_all_devices.jpg"}
                        alt="Всички ваши файлове на всички ваши устройства"
                        />

                        <Carousel.Caption>
                        <h3 style={{color: "black"}}>Всички ваши файлове на всички ваши устройства</h3>
                        <p style={{color: "black"}}>Достъп до всичките ви файлове от всякъде</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL + "ccloud_secure.jpg"}
                        alt="Само вие можедте да решите с кого да споделите вашите файлове"
                        />

                        <Carousel.Caption>
                        <h3>Само вие можедте да решите с кого да споделите вашите файлове</h3>
                        <p>Споделете фаълове с вашето семейство или колеги</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <Container style={{padding:"3em"}} id="demo">
                    <Row>
                        <Col sm={12} lg={6} md={6} xs={12}>
                            <Card style={{ textAlign:'center' }}>
                                <Card.Img style={this.settings.theme} variant="top" src={`${process.env.PUBLIC_URL}demo.png`} />
                                <Card.Body>
                                    <Card.Title>ДЕМО АКАУНТ</Card.Title>
                                    <Card.Text>Пробвате CCLOUD с демо акаунт. <br/>ПОМНЕТЕ, не качвайте важни файлове! <br/>Демо акаунт:<br/>Username: demo, Password: demo</Card.Text>
                                    <Button style={this.settings.theme} onClick={() => {window.open("https://cloud.ccloud.systems")}}>ПРОБВАЙ СЕГА</Button>
                                    <br/>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={12} lg={6} md={6} xs={12}>
                            <Card style={{ textAlign:'center' }}>
                                <Card.Img style={this.settings.theme} variant="top" src={`${process.env.PUBLIC_URL}free_trial.png`} />
                                <Card.Body>
                                    <Card.Title>ПРОБВАЙ БЕЗПЛАТНО!</Card.Title>
                                    <Card.Text>Всички наши планове предлагат 10 ДНИ БЕЗПЛАТЕН ПРОБЕН ПЕРИОД <br/>Просто се регистрирай. <br/> И не забравяй да потвърдиш имейл адреса си.<br/>И след няколко минути всичко ще е готове<br/>ДА СЕ НАСЛАТИШ НА ТВОЯ CCLOUD</Card.Text>
                                    <Button style={this.settings.theme} onClick={() => {this.props.history.push("/register");}}>РЕГИСТРИРАЙ СЕ</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Container style={{padding:"3em"}} id="pricing">
                    <Row>
                        <Col sm={12} lg={4} md={4} xs={12}>
                            <PricingElement 
                                size={"10 GB"}
                                content={`Вие получавате 10 GB пространство за вашите файлове и
                                всички невероятни възможности които CCLOUD предлага.
                                Всички наши планове предлагат 10 ДНИ БЕЗПЛАТЕН ПРОБЕН ПЕРИОД`}
                                price={`${Settings.price_list.month['10240']}€/Месец или ${Settings.price_list.year['10240']}€/Година`}
                                safe={`${Settings.price_list.month['10240']}€`}
                                image={`${process.env.PUBLIC_URL}10-gb.png`}
                                item='10G'
                                handleSelectPlan={this.handleSelectPlan}
                            />
                        </Col>
                        <Col sm={12} lg={4} md={4} xs={12}>
                            <PricingElement 
                                size={"20 GB"}
                                content={`Вие получавате 20 GB пространство за вашите файлове и
                                всички невероятни възможности които CCLOUD предлага.
                                Всички наши планове предлагат 10 ДНИ БЕЗПЛАТЕН ПРОБЕН ПЕРИОД`}
                                price={`${Settings.price_list.month['‭20480‬']}€/Месец или ${Settings.price_list.year['‭20480‬']}€/Година`}
                                safe={`${Settings.price_list.month['‭20480‬']}€`}
                                image={`${process.env.PUBLIC_URL}20-gb.png`}
                                item='20G'
                                handleSelectPlan={this.handleSelectPlan}
                            />
                        </Col>
                        <Col sm={12} lg={4} md={4} xs={12}>
                            <PricingElement 
                                size={"50 GB"}
                                content={`Вие получавате 50 GB пространство за вашите файлове и
                                всички невероятни възможности които CCLOUD предлага.
                                Всички наши планове предлагат 10 ДНИ БЕЗПЛАТЕН ПРОБЕН ПЕРИОД.`}
                                price={`${Settings.price_list.month['‭51200‬']}€/Месец или ${Settings.price_list.year['‭51200‬']}€/Година`}
                                safe={`${Settings.price_list.month['‭51200‬']}€`}
                                image={`${process.env.PUBLIC_URL}50-gb.png`}
                                item='50G'
                                handleSelectPlan={this.handleSelectPlan}
                            />
                        </Col>
                    </Row>
                </Container>
                <Container style={{padding:"3em", textAlign:"center"}}>
                Споделяйте и коментирайте документи, изпращайте и получавайте имейли, управлявайте вашия календар и провеждайте видео разговори без изтичане на информация.
                </Container>

                <Container style={{padding:"3em"}} id='why_ccloud'>
                    <Row><h1 style={{width:'100%', "textAlign": 'center'}}>Защо CCLOUD?</h1></Row>
                    <br/>
                    <Row>
                        <Col>
                            <Card style={{ textAlign:'center' }}>
                                <Card.Img style={this.settings.theme} variant="top" src={`${process.env.PUBLIC_URL}control.png`} />
                                <Card.Body>
                                    <Card.Title>Контрол</Card.Title>
                                    <Card.Text>Защитавате, контролирате и следите информацията и комуникацията във вашата компания. Гарантирано съответствие с бизнес и правни изисквания. Нищо не изтича, дори и метаданни.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card style={{ textAlign:'center' }}>
                                <Card.Img style={this.settings.theme} variant="top" src={`${process.env.PUBLIC_URL}productivity.png`} />
                                <Card.Body>
                                    <Card.Title>Продуктивност</Card.Title>
                                    <Card.Text>Бъдете продуктивни на всяка платформа, без значение дали в офиса или на път. Можете да споделяте, да работите заедно и да комуникирате извън границите на вашата организация. CCLOUD предоставя прозрачнен достъп до вашите данни на всички устройства.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Container style={{padding:"3em"}} id='support'>
                    <Row><h1 style={{width:'100%', "textAlign": 'center'}}>ПОМОЩ И ИНДИВИДУАЛНИ ПЛАНОВЕ</h1></Row>
                    <br/>
                    <Row>
                        <Col>
                            <Card style={{ textAlign:'center' }}>
                                <Card.Img style={this.settings.theme} variant="top" src={`${process.env.PUBLIC_URL}support.png`} />
                                <Card.Body>
                                    <Card.Title>ПОМОЩ</Card.Title>
                                    <Card.Text>Ако срещнете проблеми или имате въпроси, не се колебайте да се свържете с нас</Card.Text>
                                    <Button style={this.settings.theme} href={"mailto:support@ccloud.systems"}>ПОМОЩ</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card style={{ textAlign:'center' }}>
                                <Card.Img style={this.settings.theme} variant="top" src={`${process.env.PUBLIC_URL}customization.png`} />
                                <Card.Body>
                                    <Card.Title>ИНДИВИДУАЛЕН ПЛАН</Card.Title>
                                    <Card.Text>Ако имате нужда от повече пространство или допълнителни функции, не се колебайте да се свържете с нас</Card.Text>
                                    <Button style={this.settings.theme} href={"mailto:office@ccloud.systems"}>ПРОСТО ПОПИТАЙ</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default Home;
